.region-avalon-kundecenter .pagination {
  margin: 4rem auto 0;
}
.region-avalon-kundecenter .pagination__link {
  background-color: rgba(0, 75, 50, 0.2);
  border-radius: 50%;
  height: 1rem;
  width: 1rem;
  display: inline-block;
  margin: 0 0.5rem;
}
.region-avalon-kundecenter .pagination__link.is-active {
  background-color: #004b32;
}
