.region-avalon-kundecenter .faq-article__title {
  color: #2d2d2d;
  font-size: 3.7rem;
  font-weight: bold;
  text-transform: none;
  margin-bottom: 1rem;
}
.region-avalon-kundecenter .faq-article__brand-logo {
  border-radius: 5px;
  padding: 0.5rem 1rem;
  max-height: 2.5rem;
}
.region-avalon-kundecenter .faq-article__body {
  color: #2d2d2d;
  font-size: 1.6rem;
  margin-bottom: 1rem;
}
.region-avalon-kundecenter .faq-article__body img,
.region-avalon-kundecenter .faq-article__body table {
  max-width: 100%;
}
