.region-avalon-kundecenter .faq-feedback__title {
  color: #2d2d2d;
  font-size: 1.8rem;
  font-weight: bold;
  text-transform: none;
  margin-bottom: 1rem;
}
.region-avalon-kundecenter .faq-feedback__button-container,
.region-avalon-kundecenter .faq-feedback__liked-container,
.region-avalon-kundecenter .faq-feedback__disliked-container {
  border: 1px solid #004b32;
  border-radius: 5px;
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  overflow: hidden;
  margin-bottom: 5rem;
}
.region-avalon-kundecenter .faq-feedback__button {
  background: none;
  border: 0;
  width: 50%;
  height: 7rem;
  outline: 0;
}
.region-avalon-kundecenter .faq-feedback__button:hover,
.region-avalon-kundecenter .faq-feedback__button:active {
  animation: kundecenter-vertical-slide 1s infinite linear;
}
.region-avalon-kundecenter .faq-feedback__seperator {
  background-color: #004b32;
  width: 1px;
  margin: 1rem 0;
}
.region-avalon-kundecenter .faq-feedback__button-icon {
  fill: #004b32;
}
.region-avalon-kundecenter .faq-feedback__liked-container,
.region-avalon-kundecenter .faq-feedback__disliked-container {
  padding: 3rem;
  margin-bottom: 3rem;
  text-align: center;
  display: none;
}
.region-avalon-kundecenter .has-liked .faq-feedback__button-container,
.region-avalon-kundecenter .has-disliked .faq-feedback__button-container {
  display: none;
}
.region-avalon-kundecenter .has-liked .faq-feedback__liked-container {
  display: block;
}
.region-avalon-kundecenter .has-disliked .faq-feedback__disliked-container {
  display: block;
}
