@keyframes kundecenter-horizontal-slide {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-0.5rem);
  }
  75% {
    transform: translateX(0.5rem);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes kundecenter-vertical-slide {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-0.5rem);
  }
  75% {
    transform: translateY(0.5rem);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes kundecenter-shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
.region-avalon-kundecenter .dockableContainer {
  position: fixed;
  bottom: 8rem;
  right: 2rem;
}
.region-avalon-kundecenter .chat__button {
  position: fixed;
  z-index: 1;
  bottom: 2rem;
  right: 2rem;
}
.region-avalon-kundecenter .embeddedServiceHelpButton .helpButton .uiButton,
.region-avalon-kundecenter .chat__button-link {
  background-color: #004b32;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.25) 0 0 1rem 0;
  height: 7.5rem;
  width: 7.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.region-avalon-kundecenter .embeddedServiceHelpButton .helpButton .uiButton:hover,
.region-avalon-kundecenter .chat__button-link:hover,
.region-avalon-kundecenter .embeddedServiceHelpButton .helpButton .uiButton:active,
.region-avalon-kundecenter .chat__button-link:active {
  animation: kundecenter-shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}
.region-avalon-kundecenter .embeddedServiceHelpButton .helpButton .uiButton {
  display: none;
  padding: 0;
  min-width: auto;
  position: fixed;
  z-index: 5;
  bottom: 2rem;
  right: 2rem;
  background: #004b32 url('/Components/DanskeSpil/Domain/AvalonKundecenter/Graphics/SpriteSheets/Icons/kc-chat.svg') no-repeat 50% 50%;
}
.region-avalon-kundecenter .embeddedServiceHelpButton .helpButton .uiButton:before,
.region-avalon-kundecenter .embeddedServiceHelpButton .helpButton .uiButton:focus {
  display: none;
}
.region-avalon-kundecenter .embeddedServiceHelpButton .helpButton .uiButton .embeddedServiceIcon:before {
  display: none;
}
.region-avalon-kundecenter .embeddedServiceHelpButton .helpButton .uiButton .helpButtonLabel {
  display: none;
}
.region-avalon-kundecenter .embeddedServiceHelpButton .helpButton .uiButton.helpButtonEnabled {
  display: block;
}
.region-avalon-kundecenter .do-animation .chat__button-link {
  animation: kundecenter-shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}
.region-avalon-kundecenter .chat__button-icon {
  fill: white;
}
