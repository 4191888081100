.region-avalon-kundecenter .faq-backlink__link {
  display: inline-flex;
  align-items: center;
  margin: 2rem 0;
  color: #004b32;
}
.region-avalon-kundecenter .faq-backlink__link:hover,
.region-avalon-kundecenter .faq-backlink__link:active {
  text-decoration: underline;
}
.region-avalon-kundecenter .faq-backlink__icon {
  fill: #004b32;
  margin-right: 0.5rem;
}
