@import (reference) '../_imports';
@import './../Animations.less';

.region-avalon-kundecenter {
  .avalon-list-container {
    background-color: @ds-white;
  }

  .avalon-list__title {
    color: @ds-color-primary--text;
    font-weight: bold;
    text-transform: unset;
    margin-bottom: 0;
    font-size: 2rem;
  }

  .avalon-list__item {
    border-bottom: 1px solid @border-color;
    // IE11 vertical align fix
    display: flex;
    flex-direction: column;
  }

  .avalon-list__link {
    color: @ds-green-500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 0;
    min-height: 7rem;
    font-weight: 600;
    line-height: 1.56;

    &:hover,
    &:active {
      .avalon-list__link-icon {
        animation: kundecenter-horizontal-slide 1s infinite linear;
      }    
    }
  }

  .avalon-list__link-icon {
    min-width: 1.5rem;
    margin-left: 1rem;
    margin-right: 0.5rem;
  }
}
