.region-avalon-kundecenter .faq-breadcrumb {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 1.6rem;
}
.region-avalon-kundecenter .faq-breadcrumb__link {
  color: #004b32;
}
.region-avalon-kundecenter .faq-breadcrumb__link:hover,
.region-avalon-kundecenter .faq-breadcrumb__link:active {
  text-decoration: underline;
}
.region-avalon-kundecenter .faq-bradcrumb__separator {
  fill: #2d2d2d;
  margin: 0 0.5rem;
}
