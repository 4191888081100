@import url("../variables.less");

.top-navigation-theme-kundecenter {

  @theme-color-a: #014B32;
  @theme-color-b: #026B46;

  .menu-toggle-right {
    background-color: @theme-color-b;
  }

  .mobile-user-info {
    display: none;
  }


  .brand-nav {
    background-color: @theme-color-b;

    .user-bar {

      @media all and (max-width: (@nav-medium)) {
        .choose-account {
          display: inline-block !important; //Show this whether we're logged in or not
        }

        .profile-btn {
          display: none !important;
        }
      }

      .login-btn {
        display: none;
      }
    }
  }

  .corporate-nav {
    background-color: @theme-color-a;

    .accountmenu {
      .login-btn,
      .signup-btn,
      .profile-btn,
      .info-bar-item--ballance,
      .accountmenu__group,
      .sub-navigation {
        display: none !important;
      }
    }

    .choose-account-btn {
      display: inline-block !important; //Show this whether we're logged in or not
    }
  }

  .mobile-navigation {
    &-header {
      background-color: @theme-color-b;
    }

    &-item {
      &-link {
        &:before {
          background-color: @theme-color-a;
        }
      }
    }
  }


  .mobile-navigation {
    &-header {
      background-color: @theme-color-a;
    }

    &-footer {
      &-icon {
        background-color: @theme-color-b;
      }
    }

    &-item {
      &-link {
        &:before {
          background-color: @theme-color-b;
        }
      }
    }
  }


  .corporate-nav__navigation,
  .brand-nav__navigation {
    @media all and (min-width: (@nav-medium)) {
      .corporate-nav__list-link {
        color: #fff;

        &:hover {
          color: #fff;
        }
      }
    }

    .brand-nav__link {
      @media all and (min-width: (@nav-medium)) {
        color: #fff;
      }

      &:before {
        background-color: #7f4488;
      }
    }
  }

  .accountmenu {
    &__item {
      color: #fff;
    }
  }

  .mega-menu {
    &__campaign {
      &__cta-button {
        background-color: @theme-color-b;
        color: white;

        &:hover {
          background-color: @theme-color-a;
        }
      }
    }
  }
}

// For the new Kundecenter theme we don't have the brand navigation. Unfortunately this cannot be unset in Sitecore
.top-navigation-theme-kundecenter .brand-nav,
.header-static-fix {
  @media all and (min-width: @nav-medium) {
    display: none;
  }
}