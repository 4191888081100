@keyframes kundecenter-horizontal-slide {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-0.5rem);
  }
  75% {
    transform: translateX(0.5rem);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes kundecenter-vertical-slide {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-0.5rem);
  }
  75% {
    transform: translateY(0.5rem);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes kundecenter-shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
.region-avalon-kundecenter .avalon-list-container {
  background-color: white;
}
.region-avalon-kundecenter .avalon-list__title {
  color: #2d2d2d;
  font-weight: bold;
  text-transform: unset;
  margin-bottom: 0;
  font-size: 2rem;
}
.region-avalon-kundecenter .avalon-list__item {
  border-bottom: 1px solid #c5c5c5;
  display: flex;
  flex-direction: column;
}
.region-avalon-kundecenter .avalon-list__link {
  color: #004b32;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0;
  min-height: 7rem;
  font-weight: 600;
  line-height: 1.56;
}
.region-avalon-kundecenter .avalon-list__link:hover .avalon-list__link-icon,
.region-avalon-kundecenter .avalon-list__link:active .avalon-list__link-icon {
  animation: kundecenter-horizontal-slide 1s infinite linear;
}
.region-avalon-kundecenter .avalon-list__link-icon {
  min-width: 1.5rem;
  margin-left: 1rem;
  margin-right: 0.5rem;
}
