.region-avalon-kundecenter .faq-form {
  padding: 1rem;
  background-color: #f3f3f3;
}
@media screen and (min-width: 767px) {
  .region-avalon-kundecenter .faq-form {
    padding-top: 5rem;
  }
}
.region-avalon-kundecenter .faq-form__header {
  padding: 1rem;
  margin-left: -1rem;
  margin-right: -1rem;
  margin-bottom: 3rem;
  border-bottom: 2px solid #c5c5c5;
}
@media screen and (min-width: 480px) {
  .region-avalon-kundecenter .faq-form__header {
    margin-left: 0;
    margin-right: 0;
  }
}
.region-avalon-kundecenter .faq-form__title {
  text-transform: none;
  font-size: 2.4rem;
  color: #2d2d2d;
}
@media screen and (min-width: 480px) {
  .region-avalon-kundecenter .faq-form__title {
    text-align: center;
  }
}
.region-avalon-kundecenter .faq-form__description {
  font-size: 1.6rem;
  font-weight: 400;
  color: #2d2d2d;
  text-transform: none;
}
@media screen and (min-width: 480px) {
  .region-avalon-kundecenter .faq-form__description {
    text-align: center;
  }
}
.region-avalon-kundecenter .faq-form__wrap {
  margin: auto;
}
@media screen and (min-width: 480px) {
  .region-avalon-kundecenter .faq-form__row {
    display: flex;
    justify-content: space-between;
  }
  .region-avalon-kundecenter .faq-form__row .faq-form__column {
    width: calc(50% - 0.5rem);
  }
}
.region-avalon-kundecenter .faq-form__group {
  margin-bottom: 5rem;
}
.region-avalon-kundecenter .faq-form__group-title {
  text-transform: none;
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 2rem;
}
.region-avalon-kundecenter .faq-form__input-group {
  margin-bottom: 1.6rem;
}
.region-avalon-kundecenter .faq-form__label {
  display: inline-block;
  font-size: 1.6rem;
  color: #2d2d2d;
  margin-bottom: 0.5rem;
}
.region-avalon-kundecenter .is-required .faq-form__label:after {
  content: '*';
  color: #c50005;
}
.region-avalon-kundecenter .faq-form__input-wrap {
  position: relative;
}
.region-avalon-kundecenter .faq-form__input-icon {
  margin-right: 1rem;
}
.region-avalon-kundecenter .faq-form__state-icon {
  position: absolute;
  height: 1rem;
  width: 1rem;
  top: calc(50% - 0.5rem);
  right: 1rem;
  opacity: 0;
}
.region-avalon-kundecenter .faq-form__input,
.region-avalon-kundecenter .faq-form__textarea,
.region-avalon-kundecenter .faq-form__styled-select {
  background-color: white;
  box-sizing: border-box;
  border: 1px solid #c5c5c5;
  border-radius: 5px;
  display: block;
  padding: 1rem 0;
  text-indent: 1rem;
  line-height: 1.5;
  width: 100%;
  outline: 0;
}
.region-avalon-kundecenter .faq-form__input:focus,
.region-avalon-kundecenter .faq-form__textarea:focus,
.region-avalon-kundecenter .faq-form__styled-select:focus {
  border-color: #2d2d2d;
}
.region-avalon-kundecenter .faq-form__input .faq-form__styled-select-icon,
.region-avalon-kundecenter .faq-form__textarea .faq-form__styled-select-icon,
.region-avalon-kundecenter .faq-form__styled-select .faq-form__styled-select-icon {
  position: absolute;
  top: calc(50% - 0.75rem);
  right: 1rem;
  z-index: 1;
}
@media screen and (min-width: 767px) {
  .region-avalon-kundecenter .faq-form__input .faq-form__select--mobile,
  .region-avalon-kundecenter .faq-form__textarea .faq-form__select--mobile,
  .region-avalon-kundecenter .faq-form__styled-select .faq-form__select--mobile {
    display: none;
  }
}
.region-avalon-kundecenter .faq-form__input .faq-form__select--desktop,
.region-avalon-kundecenter .faq-form__textarea .faq-form__select--desktop,
.region-avalon-kundecenter .faq-form__styled-select .faq-form__select--desktop {
  display: none;
  padding: 1rem 0;
  text-indent: 1rem;
  cursor: pointer;
  position: relative;
  z-index: 2;
}
@media screen and (min-width: 767px) {
  .region-avalon-kundecenter .faq-form__input .faq-form__select--desktop,
  .region-avalon-kundecenter .faq-form__textarea .faq-form__select--desktop,
  .region-avalon-kundecenter .faq-form__styled-select .faq-form__select--desktop {
    display: block;
  }
}
.region-avalon-kundecenter .faq-form__input .faq-form__select,
.region-avalon-kundecenter .faq-form__textarea .faq-form__select,
.region-avalon-kundecenter .faq-form__styled-select .faq-form__select {
  position: relative;
  z-index: 2;
  background-color: transparent;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  border: 0;
  outline: none;
  padding: 1rem 0;
  text-indent: 1rem;
}
.region-avalon-kundecenter .faq-form__textarea {
  padding: 1rem;
  text-indent: 0;
}
.region-avalon-kundecenter .faq-form__styled-dropdown {
  background-color: white;
  border: 1px solid #c5c5c5;
  border-top: 0;
  padding: 0 1rem;
  width: 100%;
  position: absolute;
  z-index: 2;
}
.region-avalon-kundecenter .faq-form__styled-dropdown .avalon-list__item:last-of-type {
  border-bottom: 0;
}
@media screen and (min-width: 767px) {
  .region-avalon-kundecenter .is-open .faq-form__styled-dropdown {
    display: block;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }
}
@media screen and (min-width: 767px) {
  .region-avalon-kundecenter .is-open .faq-form__styled-select {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}
.region-avalon-kundecenter .is-open .faq-form__styled-select-icon {
  transform: rotate(180deg);
}
.region-avalon-kundecenter .faq-form__brand-container {
  position: relative;
}
.region-avalon-kundecenter .faq-form__brand-container .faq-brand-menu__button-text {
  display: inline-block;
}
.region-avalon-kundecenter .faq-form__brand-container .faq-form__selected-brand {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  height: 4.5rem;
}
.region-avalon-kundecenter .faq-form__brand-container .faq-form__selected-brand .faq-brand-menu__button-icon {
  fill: white;
}
.region-avalon-kundecenter .faq-form__brand-container .faq-form__selected-brand-image {
  max-height: 2.8rem;
  max-width: 10rem;
}
.region-avalon-kundecenter .faq-form__brand-container .faq-brand-menu {
  display: block;
}
.region-avalon-kundecenter .faq-form__brand-container .faq-brand-menu__items {
  margin-bottom: 0;
}
.region-avalon-kundecenter .faq-form__brand-container .faq-brand-menu__image {
  width: 14rem;
  border-radius: 0.5rem;
  padding: 1rem;
  height: 4rem;
}
.region-avalon-kundecenter .faq-form__styled-select {
  position: relative;
  padding: 0;
  text-indent: 0;
}
.region-avalon-kundecenter .faq-form__input-button {
  cursor: pointer;
  text-align: left;
  position: relative;
}
.region-avalon-kundecenter .faq-form__input-button-icon {
  fill: #2d2d2d;
  transition: all 0.5s;
}
.region-avalon-kundecenter .faq-form__textarea {
  min-height: 20rem;
  max-width: 100%;
}
.region-avalon-kundecenter .recaptcha-container {
  text-align: center;
}
.region-avalon-kundecenter .faq-form__recaptcha {
  display: inline-block;
  margin: 0 auto 4rem;
}
.region-avalon-kundecenter .faq-form__recaptcha-error {
  color: #c50005;
  margin-bottom: 2rem;
}
.region-avalon-kundecenter .faq-form__file-list {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}
.region-avalon-kundecenter .faq-form__file-list .faq-form__file-item-wrap {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 1.6rem;
}
@media screen and (min-width: 480px) {
  .region-avalon-kundecenter .faq-form__file-list .faq-form__file-item-wrap {
    width: calc(50% - 0.5rem);
  }
}
.region-avalon-kundecenter .faq-form__file-list .faq-form__file-item {
  background-color: white;
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #c5c5c5;
  border-radius: 5px;
  padding: 1rem 3.5rem 1rem 1rem;
  position: relative;
}
.region-avalon-kundecenter .faq-form__file-list .faq-form__file-image {
  height: 2rem;
  width: 2rem;
  margin-right: 1rem;
  object-fit: cover;
}
.region-avalon-kundecenter .faq-form__file-list .faq-form__file-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.region-avalon-kundecenter .faq-form__file-list .faq-form__file-remove {
  position: absolute;
  top: calc(50% - 1rem);
  right: 1rem;
  height: 2rem;
  width: 2rem;
  background-color: #c5c5c5;
  color: white;
  border-radius: 50%;
  border: 0;
  outline: 0;
  padding: 0;
}
.region-avalon-kundecenter .faq-form__file-list .has-error {
  color: #c50005;
}
.region-avalon-kundecenter .has-error .faq-form__input,
.region-avalon-kundecenter .has-error .faq-form__textarea {
  border-color: #c50005;
  color: #c50005;
}
.region-avalon-kundecenter .has-error .faq-form__state-icon {
  fill: #c50005;
  opacity: 1;
}
.region-avalon-kundecenter .has-error .faq-form__file-error {
  color: #c50005;
}
.region-avalon-kundecenter .is-valid .faq-form__state-icon {
  fill: #004b32;
  opacity: 1;
}
.region-avalon-kundecenter .faq-form__error-message-container {
  margin-bottom: 2rem;
}
.region-avalon-kundecenter .faq-form__error-message {
  color: #c50005;
  max-width: 100%;
}
.region-avalon-kundecenter .faq-form__error-message--center {
  text-align: center;
}
.region-avalon-kundecenter .faq-form__update-option {
  border: 1px solid #004b32;
  padding: 1rem;
  border-radius: 5px;
  display: block;
}
.region-avalon-kundecenter .faq-form__update-option--success {
  color: #004b32;
}
.region-avalon-kundecenter .faq-form__update-option--error {
  border: 1px solid #c50005;
  color: #c50005;
}
.region-avalon-kundecenter .faq-form__button {
  border: 0;
  border-radius: 5px;
  color: white;
  background-color: #004b32;
  width: 100%;
  min-height: 5rem;
  height: 5rem;
  max-width: 25rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.region-avalon-kundecenter .faq-form__file {
  position: absolute;
  left: -999999px;
}
.region-avalon-kundecenter .faq-form__receipt-page {
  padding: 5rem 0;
  text-align: center;
}
.region-avalon-kundecenter .faq-form__receipt-header {
  margin-bottom: 2rem;
}
.region-avalon-kundecenter .faq-form__receipt-icon {
  width: 14rem;
  height: 14rem;
  margin-bottom: 2rem;
}
