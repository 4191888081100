@import (reference) '../_imports';

.region-avalon-kundecenter {
  .faq-brand-menu__container {
    display: flex;
    order: 2;
    width: 21rem;
    margin-top: 3rem;
    position: relative;

    @media @sm {
      flex: 0 0 40%;
      order: -1;
      margin-top: 0;
    }
    
    @media @md {
      flex: 0 0 21rem;
    }

    &.is-open {
      .faq-brand-menu {
        display: block;
      }

      .faq-brand-menu__button-icon {
        transform: rotate(-180deg);
      }
    }
  }

  .faq-brand-menu__button {
    background-color: @ds-green-500;
    color: @ds-white;
    border: none;
    width: 100%;
    min-height: 5rem;
    padding: 0 1rem;
    position: absolute;
    border-radius: 5px;

    @media screen and (min-width: @medium) {
      position: relative;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .faq-brand-menu__button-image {
    display: flex;
    max-width: 12rem;
    max-height: 3rem;
    margin: 0 auto;
  }

  .faq-brand-menu__button-icon {
    fill: @ds-white;
    position: absolute;
    right: 1.5rem;
    top: ~"calc(50% - 0.75rem)";
    transition: all 200ms;
  }

  .has-suggestions {
    @media screen and (min-width: @medium) {
      .faq-brand-menu__button {
        border-bottom-left-radius: 0;
      }
    }
  }

  .faq-brand-menu {
    background-color: @ds-white;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: rgba(0,0,0,0.25) 2px 2px 16px 0;
    padding: 1rem;
    display: none;
    width: 100%;
    position: absolute;
    left: 0;
    top: 5rem;
    z-index: 5;
    text-align: center;
  }

  .faq-brand-menu__title {
    font-size: 1rem;
    text-transform: none;
    color: @ds-color-primary--text;
  }

  .faq-brand-menu__items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 2rem;
  }

  .faq-brand-menu__link {
    width: ~"calc(50% - 0.5rem)";
    display: block;
  }

  .faq-brand-menu__image {
    width: 9rem;
    border-radius: 0.5rem;
    padding: 0.5rem;
    height: 3rem;
  }

  .faq-brand-menu__close-button {
    background-color: @ds-green-500;
    color: @ds-white;
    border: 0;
    border-radius: 50%;
  }

  .two-col__col--left {
    .faq-brand-menu__container {
      margin-top: 3rem;
      order: 2;
      max-width: 21rem;
    }

    .faq-brand-menu__button {
      border-radius: 5px;
    }
  }
}
