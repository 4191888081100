@keyframes kundecenter-horizontal-slide {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-0.5rem);
  }
  75% {
    transform: translateX(0.5rem);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes kundecenter-vertical-slide {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-0.5rem);
  }
  75% {
    transform: translateY(0.5rem);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes kundecenter-shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
.region-avalon-kundecenter .avalon-contact {
  background: #004b32;
  color: white;
  text-align: center;
  font-size: 1.6rem;
  font-weight: normal;
}
.region-avalon-kundecenter .avalon-contact__item {
  width: calc(100% - 4rem);
  margin: 0 2rem 2rem;
}
@media screen and (min-width: 480px) {
  .region-avalon-kundecenter .avalon-contact__item {
    display: inline-block;
    vertical-align: top;
    width: calc(36%);
  }
}
@media screen and (min-width: 1024px) {
  .region-avalon-kundecenter .avalon-contact__item {
    width: calc(26%);
  }
}
.region-avalon-kundecenter .avalon-contact__title {
  font-size: 1.8rem;
  font-weight: bold;
  color: white;
  text-transform: none;
}
.region-avalon-kundecenter .avalon-contact__abstract {
  margin-bottom: 2rem;
}
.region-avalon-kundecenter .avalon-contact__item-title {
  font-size: 1.6rem;
  font-weight: bold;
  text-transform: none;
}
.region-avalon-kundecenter .avalon-contact__item-link {
  background: var(--contact__item-backgroundColor, white);
  border-radius: 5px;
  color: var(--contact__item-color, #2d2d2d);
  display: block;
  padding: 1rem 0;
  border: solid var(--contact__item-borderColor, --contact__item-backgroundColor, white) 3px;
}
.region-avalon-kundecenter .avalon-contact__bottom-link {
  border-top: 4px solid #006e49;
  background-color: #005c3e;
  color: white;
  display: block;
  padding: 1rem;
  text-transform: uppercase;
}
@media screen and (min-width: 1024px) {
  .region-avalon-kundecenter .avalon-contact__bottom-link {
    display: none;
  }
}
.region-avalon-kundecenter .avalon-contact__bottom-link:hover .avalon-contact__bottom-link-icon,
.region-avalon-kundecenter .avalon-contact__bottom-link:active .avalon-contact__bottom-link-icon {
  animation: kundecenter-horizontal-slide 1s infinite linear;
}
.region-avalon-kundecenter .avalon-contact__bottom-link-icon {
  fill: white;
  margin-left: 1rem;
}
