@import (reference) '../_imports';

.region-avalon-kundecenter {
  .pagination {
    margin: 4rem auto 0;
  }

  .pagination__link {
    background-color: fade(@ds-green-500, 20%);
    border-radius: 50%;
    height: 1rem;
    width: 1rem;
    display: inline-block;
    margin: 0 0.5rem;

    &.is-active {
      background-color: @ds-green-500;
    }
  }
}
