@import (reference) '../_imports';
@import './../Animations.less';

.region-avalon-kundecenter {
  .announcements {
    padding: 3rem 0;
    background-color: @background-color;
  }

  .announcements__title {
    color: @ds-color-primary--text;
    font-size: 2.2rem;
    font-weight: bold;
    text-transform: none;
    padding: 0 3rem;
    max-width: @large;
    margin-left: auto;
    margin-right: auto;
  }

  .announcements__list {
    display: flex;
    width: fit-content;
    padding: 0 3rem;
    margin: 0 auto;
  }

  .announcement__item {
    background-color: @ds-white;
    box-sizing: border-box;
    color: @ds-dark-green-500;
    border-radius: 5px;
    border: 1px solid @border-color;
    padding: 2rem;
    display: inline-block;
    vertical-align: top;
    width: 26rem;
    margin-right: 1rem;
    font-size: 1.6rem;
    position: relative;
    max-height: 20rem;
    overflow-y: hidden;
    height: auto; // Reset the default swiper-slide height: 100% (we need equal height boxes here

    @media screen and (min-width: @medium) {
      width: 30rem;
      margin-right: 3rem;
    }

    &:nth-last-child(1) {
      margin-right: 0;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      box-shadow: 0 -0.5rem 1rem 1rem @ds-white;
    }

    &:hover,
    &:active {
      .announcement__icon {
        animation: kundecenter-horizontal-slide 1s infinite linear;
      }    
    }
  }

  .announcement__brand-logo {
    display: inline-block;

    // Temp placeholder
    background-color: @background-color;
    height: 14px;
    width: 80px;
  }

  .announcement__title {
    font-weight: bold;
    text-transform: none;
    display: block;
  }

  .announcement__abstract {
    display: block;
    overflow: hidden;
  }

  .announcement__icon {
    position: absolute;
    z-index: 1;
    bottom: 1rem;
    right: 1rem;

    @media screen and (min-width: @medium) {
      bottom: 2rem;
      right: 2rem;
    }
  }

  .has-swiper {
    .swiper-wrapper {
      margin-bottom: 5rem !important;
    }

    .swiper-scrollbar {
      overflow: hidden;
      width: 50%;
      height: 1rem;
      max-width: 50%;
      transform: translateX(50%);
      bottom: 0;
      margin: 0 0 2rem;
    }
  
    .swiper-scrollbar-drag {
      position: relative;
      height: 3rem;
      width: 3rem;
      background-color: @ds-color-primary--text;
      margin-top: -1rem;
      z-index: 100;
    }
  }
}
