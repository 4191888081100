// We're sort of twisting the arm of the avalon-video component into this CSR spot.
// The Business wants the exact same functionality, but another visual apperance.
.region-avalon-kundecenter {
  .card-layout-10 {
    .card.card-picture {
      padding: 0;
    }

    .avalon-video {
      background-color: transparent;
      width: 100%;

      .avalon-video__video-inner {
        padding-bottom: 25%;
      }

      .avalon-video__title,
      .avalon-video__text {
        display: none;
      }

      .u-grid {
        padding: 0;
        max-width: none;
      }
    }
  }
}