@import (reference) '../_imports';

.region-avalon-kundecenter {
  .faq-search {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    padding: 1rem 3rem 7rem;
    min-height: 18rem;
    margin-bottom: -4rem;

    @media @sm {
      min-height: 21rem;
      padding: 1rem 0;
      margin-bottom: 0;
    }

    @media @md {
      padding: 1rem 3rem;
    }

    @media @lg {
      min-height: 23rem;
    }

    &--lotto {
      background-color: @ds-red-700;

      .faq-brand-menu__button {
        background-color: @ds-red-500;
      }
    }

    // Brand name wierd - this is configured in Sitecore in a sitewide config
    &--vikingelotto,
    &--vikinglotto {
      background-color: @ds-blue-700;

      .faq-brand-menu__button {
        background-color: @ds-blue-500;
      }
    }

    // Brand name resolves to "alt eller intet", so I can only detect on alt
    &--alt {
      background-color: @ds-dustypurple-700;

      .faq-brand-menu__button {
        background-color: @ds-dustypurple-900;
      }
    }

    &--eurojackpot {
      background-color: @ds-black-500;

      .faq-brand-menu__button {
        background-color: @ds-yellow-500;
      }
    }

    &--virtuel {
      background-color: @color-lightblue-100;

      .faq-brand-menu__button {
        background-color: @color-lightblue-200;
      }
    }

    &--plus {
      background-color: #2d3535;

      .faq-brand-menu__button {
        background-color: @color-brand-plus;
      }
    }

    &--bingo {
      background-color: @ds-lime-700;

      .faq-brand-menu__button {
        background-color: @ds-lime-500;
      }
    }

    &--quick {
      background-color: @ds-purple-700;

      .faq-brand-menu__button {
        background-color: @ds-purple-500;
      }
    }

    &--spillehjoernet {
      background-color: @ds-blue-gray-700;

      .faq-brand-menu__button {
        background-color: @ds-blue-gray-500;
      }
    }

    &--keno {
      background-color: @ds-light-blue-700;

      .faq-brand-menu__button {
        background-color: @ds-light-blue-500;
      }
    }

    &--eoddset,
    &--oddset {
      background-color: @ds-dark-blue-500;

      .faq-brand-menu__button {
        background-color: @ds-dark-blue-900;
      }
    }

    &--casino {
      background-color: @ds-gold-700;

      .faq-brand-menu__button {
        background-color: @ds-gold-500;
      }
    }

    &--livecasino {
      background-color: @ds-blue-gray-500;

      .faq-brand-menu__button {
        background-color: @ds-gold-500;
      }
    }

    &--poker {
      background-color: @ds-black-700;

      .faq-brand-menu__button {
        background-color: @ds-black-500;
      }
    }

    &--dantoto {
      background-color: @ds-orange-700;

      .faq-brand-menu__button {
        background-color: @ds-orange-500;
      }
    }

    &--tips {
      background-color: @ds-lightgreen-700;

      .faq-brand-menu__button {
        background-color: @ds-lightgreen-500;
      }
    }
  }

  .faq-search__search-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 55rem;
    position: relative;

    @media @sm {
      flex-wrap: wrap;
      flex-direction: row;
      margin: 0 auto;
    }

    @media @md {
      flex-wrap: nowrap;
      max-width: 97rem;
    }
  }

  .faq-search__title {
    color: @ds-white;
    font-size: 2rem;
    margin-bottom: 2rem;
    font-weight: bold;
    text-transform: none;
    width: 100%;

    @media @sm {
      font-size: 2.4rem;
      max-width: 55rem;
      margin: 0 auto 2rem;
    }

    @media @md {
      max-width: 97rem;
    }
  }

  .faq-search__input-container {
    display: flex;
    justify-content: center;
    height: 5rem;
    width: 100%;

    @media @sm {
      flex: 0 0 60%;
    }

    @media @md {
      flex: 0 0 40rem;
    }
  }

  .two-col {
    .faq-search__input-container {
      @media @sm {
        flex: 0 0 5rem;
      }

      @media @md {
        margin-bottom: 2rem;
      }
    }
  }

  .faq-search__input {
    text-indent: 1rem;
    width: 100%;
    outline: 0;
    border: 0 solid @ds-yellow;
    border-left: 2px solid transparent; // We do this, so the input text does not jump on focus
    -webkit-appearance: none;
    border-radius: 0.5rem 0 0 0.5rem;

    @media @sm {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:focus {
      border: 2px solid @ds-yellow;
    }
  }

  .faq-search__button {
    background-color: @ds-color-cta-primary;
    border: none;
    padding: 0 2rem;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    display: flex;
    align-items: center;
  }

  .faq-search__button-icon {
    fill: @ds-green-500;
    height: 2rem;
    width: 2rem;
  }

  .faq-search__suggestions {
    display: none;
    background-color: @ds-white;
    border: 2px solid @ds-yellow;
    padding: 1rem;
    width: 100%;
    position: absolute;
    left: 0;
    top: 5rem;
    z-index: 5;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    @media @sm {
      padding: 3rem 4rem;
    }
  }

  .has-suggestions {
    .faq-search__suggestions {
      display: block;
    }

    .avalon-list {
      max-height: 50rem;
      overflow-y: auto;
    }

    .faq-search__input {
      border-bottom: 0;
      border-bottom-left-radius: 0;
    }

    .faq-search__button {
      border-bottom-right-radius: 0;
    }
  }

  .two-col__col--left {
    .faq-search {
      padding: 0 1.5rem;
      justify-content: flex-start;
    }

    .faq-search__search-container {
      flex-direction: column;
    }

    .faq-search__input {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    .has-suggestions {
      .faq-search__input {
        border-bottom-left-radius: 0;
      }
    }
  }

  // If announcements or avalon-list-container is direct sibling to search, add some padding,
  // so absolute positioned brand menu button is no floating over title
  .faq-search + .announcements__container,
  .faq-search + .avalon-list-container {
    @media screen and (max-width: @medium) {
      padding-top: 6rem;
    }
  }
}
