.region-avalon-kundecenter .two-col {
  background-color: white;
  display: flex;
  justify-content: space-between;
  margin-top: 4rem;
}
.region-avalon-kundecenter .two-col__col--left {
  margin-right: 3rem;
  width: 32rem;
  display: flex;
  position: relative;
}
@media screen and (max-width: 767px) {
  .region-avalon-kundecenter .two-col__col--left {
    display: none;
  }
}
.region-avalon-kundecenter .two-col__col--right {
  flex: 1;
  padding-top: 3rem;
  padding-bottom: 3rem;
}
@media screen and (max-width: 767px) {
  .region-avalon-kundecenter .two-col__col--right {
    padding: 3rem 1rem;
  }
}
@media screen and (min-width: 767px) {
  .region-avalon-kundecenter .two-col__col--right {
    margin-left: -15rem;
  }
}
@media screen and (min-width: 1024px) {
  .region-avalon-kundecenter .two-col__col--right {
    margin-left: auto;
  }
}
