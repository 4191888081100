@import (reference) '../_imports';

.region-avalon-kundecenter {
  .faq-feedback__title {
    color: @ds-color-primary--text;
    font-size: 1.8rem;
    font-weight: bold;
    text-transform: none;
    margin-bottom: 1rem;
  }

  .faq-feedback__button-container,
  .faq-feedback__liked-container,
  .faq-feedback__disliked-container {
    border: 1px solid @ds-green-500;
    border-radius: 5px;
    display: flex;
    justify-content: space-around;
    align-items: stretch;
    overflow: hidden;
    margin-bottom: 5rem;
  }

  .faq-feedback__button {
    background: none;
    border: 0;
    width: 50%;
    height: 7rem;
    outline: 0;

    &:hover,
    &:active {
      animation: kundecenter-vertical-slide 1s infinite linear;
    }
  }

  .faq-feedback__seperator {
    background-color: @ds-green-500;
    width: 1px;
    margin: 1rem 0;
  }

  .faq-feedback__button-icon {
    fill: @ds-green-500;
  }

  .faq-feedback__liked-container,
  .faq-feedback__disliked-container {
    padding: 3rem;
    margin-bottom: 3rem;
    text-align: center;
    display: none;
  }

  .has-liked,
  .has-disliked {
    .faq-feedback__button-container {
      display: none;
    }
  }

  .has-liked {
    .faq-feedback__liked-container {
      display: block;
    }
  }

  .has-disliked {
    .faq-feedback__disliked-container {
      display: block;
    }
  }
}
