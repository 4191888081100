.region-avalon-kundecenter .faq-search {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  padding: 1rem 3rem 7rem;
  min-height: 18rem;
  margin-bottom: -4rem;
}
@media screen and (min-width: 768px) {
  .region-avalon-kundecenter .faq-search {
    min-height: 21rem;
    padding: 1rem 0;
    margin-bottom: 0;
  }
}
@media screen and (min-width: 950px) {
  .region-avalon-kundecenter .faq-search {
    padding: 1rem 3rem;
  }
}
@media screen and (min-width: 1024px) {
  .region-avalon-kundecenter .faq-search {
    min-height: 23rem;
  }
}
.region-avalon-kundecenter .faq-search--lotto {
  background-color: #a60004;
}
.region-avalon-kundecenter .faq-search--lotto .faq-brand-menu__button {
  background-color: #c50005;
}
.region-avalon-kundecenter .faq-search--vikingelotto,
.region-avalon-kundecenter .faq-search--vikinglotto {
  background-color: #0000c7;
}
.region-avalon-kundecenter .faq-search--vikingelotto .faq-brand-menu__button,
.region-avalon-kundecenter .faq-search--vikinglotto .faq-brand-menu__button {
  background-color: #0000ff;
}
.region-avalon-kundecenter .faq-search--alt {
  background-color: #312f51;
}
.region-avalon-kundecenter .faq-search--alt .faq-brand-menu__button {
  background-color: #191339;
}
.region-avalon-kundecenter .faq-search--eurojackpot {
  background-color: #000000;
}
.region-avalon-kundecenter .faq-search--eurojackpot .faq-brand-menu__button {
  background-color: #FEB700;
}
.region-avalon-kundecenter .faq-search--virtuel {
  background-color: #5998B0;
}
.region-avalon-kundecenter .faq-search--virtuel .faq-brand-menu__button {
  background-color: #166F93;
}
.region-avalon-kundecenter .faq-search--plus {
  background-color: #2d3535;
}
.region-avalon-kundecenter .faq-search--plus .faq-brand-menu__button {
  background-color: #074a32;
}
.region-avalon-kundecenter .faq-search--bingo {
  background-color: #607f15;
}
.region-avalon-kundecenter .faq-search--bingo .faq-brand-menu__button {
  background-color: #a0d323;
}
.region-avalon-kundecenter .faq-search--quick {
  background-color: #623569;
}
.region-avalon-kundecenter .faq-search--quick .faq-brand-menu__button {
  background-color: #7f4488;
}
.region-avalon-kundecenter .faq-search--spillehjoernet {
  background-color: #5b799e;
}
.region-avalon-kundecenter .faq-search--spillehjoernet .faq-brand-menu__button {
  background-color: #7D96B4;
}
.region-avalon-kundecenter .faq-search--keno {
  background-color: #0090cc;
}
.region-avalon-kundecenter .faq-search--keno .faq-brand-menu__button {
  background-color: #00a5eb;
}
.region-avalon-kundecenter .faq-search--eoddset,
.region-avalon-kundecenter .faq-search--oddset {
  background-color: #003E99;
}
.region-avalon-kundecenter .faq-search--eoddset .faq-brand-menu__button,
.region-avalon-kundecenter .faq-search--oddset .faq-brand-menu__button {
  background-color: #001d47;
}
.region-avalon-kundecenter .faq-search--casino {
  background-color: #826b40;
}
.region-avalon-kundecenter .faq-search--casino .faq-brand-menu__button {
  background-color: #a78952;
}
.region-avalon-kundecenter .faq-search--livecasino {
  background-color: #7D96B4;
}
.region-avalon-kundecenter .faq-search--livecasino .faq-brand-menu__button {
  background-color: #a78952;
}
.region-avalon-kundecenter .faq-search--poker {
  background-color: #373341;
}
.region-avalon-kundecenter .faq-search--poker .faq-brand-menu__button {
  background-color: #000000;
}
.region-avalon-kundecenter .faq-search--dantoto {
  background-color: #ad4b0d;
}
.region-avalon-kundecenter .faq-search--dantoto .faq-brand-menu__button {
  background-color: #dc5f11;
}
.region-avalon-kundecenter .faq-search--tips {
  background-color: #006d44;
}
.region-avalon-kundecenter .faq-search--tips .faq-brand-menu__button {
  background-color: #009a60;
}
.region-avalon-kundecenter .faq-search__search-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 55rem;
  position: relative;
}
@media screen and (min-width: 768px) {
  .region-avalon-kundecenter .faq-search__search-container {
    flex-wrap: wrap;
    flex-direction: row;
    margin: 0 auto;
  }
}
@media screen and (min-width: 950px) {
  .region-avalon-kundecenter .faq-search__search-container {
    flex-wrap: nowrap;
    max-width: 97rem;
  }
}
.region-avalon-kundecenter .faq-search__title {
  color: white;
  font-size: 2rem;
  margin-bottom: 2rem;
  font-weight: bold;
  text-transform: none;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .region-avalon-kundecenter .faq-search__title {
    font-size: 2.4rem;
    max-width: 55rem;
    margin: 0 auto 2rem;
  }
}
@media screen and (min-width: 950px) {
  .region-avalon-kundecenter .faq-search__title {
    max-width: 97rem;
  }
}
.region-avalon-kundecenter .faq-search__input-container {
  display: flex;
  justify-content: center;
  height: 5rem;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .region-avalon-kundecenter .faq-search__input-container {
    flex: 0 0 60%;
  }
}
@media screen and (min-width: 950px) {
  .region-avalon-kundecenter .faq-search__input-container {
    flex: 0 0 40rem;
  }
}
@media screen and (min-width: 768px) {
  .region-avalon-kundecenter .two-col .faq-search__input-container {
    flex: 0 0 5rem;
  }
}
@media screen and (min-width: 950px) {
  .region-avalon-kundecenter .two-col .faq-search__input-container {
    margin-bottom: 2rem;
  }
}
.region-avalon-kundecenter .faq-search__input {
  text-indent: 1rem;
  width: 100%;
  outline: 0;
  border: 0 solid #FEB700;
  border-left: 2px solid transparent;
  -webkit-appearance: none;
  border-radius: 0.5rem 0 0 0.5rem;
}
@media screen and (min-width: 768px) {
  .region-avalon-kundecenter .faq-search__input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
.region-avalon-kundecenter .faq-search__input:focus {
  border: 2px solid #FEB700;
}
.region-avalon-kundecenter .faq-search__button {
  background-color: #FEB700;
  border: none;
  padding: 0 2rem;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  align-items: center;
}
.region-avalon-kundecenter .faq-search__button-icon {
  fill: #004b32;
  height: 2rem;
  width: 2rem;
}
.region-avalon-kundecenter .faq-search__suggestions {
  display: none;
  background-color: white;
  border: 2px solid #FEB700;
  padding: 1rem;
  width: 100%;
  position: absolute;
  left: 0;
  top: 5rem;
  z-index: 5;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
@media screen and (min-width: 768px) {
  .region-avalon-kundecenter .faq-search__suggestions {
    padding: 3rem 4rem;
  }
}
.region-avalon-kundecenter .has-suggestions .faq-search__suggestions {
  display: block;
}
.region-avalon-kundecenter .has-suggestions .avalon-list {
  max-height: 50rem;
  overflow-y: auto;
}
.region-avalon-kundecenter .has-suggestions .faq-search__input {
  border-bottom: 0;
  border-bottom-left-radius: 0;
}
.region-avalon-kundecenter .has-suggestions .faq-search__button {
  border-bottom-right-radius: 0;
}
.region-avalon-kundecenter .two-col__col--left .faq-search {
  padding: 0 1.5rem;
  justify-content: flex-start;
}
.region-avalon-kundecenter .two-col__col--left .faq-search__search-container {
  flex-direction: column;
}
.region-avalon-kundecenter .two-col__col--left .faq-search__input {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.region-avalon-kundecenter .two-col__col--left .has-suggestions .faq-search__input {
  border-bottom-left-radius: 0;
}
@media screen and (max-width: 767px) {
  .region-avalon-kundecenter .faq-search + .announcements__container,
  .region-avalon-kundecenter .faq-search + .avalon-list-container {
    padding-top: 6rem;
  }
}
