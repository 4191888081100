@import (reference) '../_imports';
@import './../Animations.less';

.region-avalon-kundecenter {
  // Manipulate the Salesforce dynamic created DOM
  .dockableContainer {
    position: fixed;
    bottom: 8rem;
    right: 2rem;
  }

  .chat__button {
    position: fixed;
    z-index: 1;
    bottom: 2rem;
    right: 2rem;
  }

  .embeddedServiceHelpButton .helpButton .uiButton,
  .chat__button-link {
    background-color: @ds-green-500;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.25) 0 0 1rem 0;
    height: 7.5rem;
    width: 7.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover,
    &:active {
      animation: kundecenter-shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    }
  }

  .embeddedServiceHelpButton .helpButton .uiButton {
    display: none; // Hide button unless the chat is online
    padding: 0;
    min-width: auto;
    position: fixed;
    z-index: 5;
    bottom: 2rem;
    right: 2rem;
    background: @ds-green-500 url('/Components/DanskeSpil/Domain/AvalonKundecenter/Graphics/SpriteSheets/Icons/kc-chat.svg') no-repeat 50% 50%;

    // Kill the Salesforce generated hover color
    &:before,
    &:focus {
      display: none;
    }

    .embeddedServiceIcon {
      &:before {
        display: none;
      }
    }

    .helpButtonLabel {
      display: none;
    }

    &.helpButtonEnabled {
      display: block;
    }
  }

  .do-animation .chat__button-link {
    animation: kundecenter-shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  }

  .chat__button-icon {
    fill: @ds-white;
  }
}
