﻿@import (reference) '../_imports';

.kvn-information {
  position: relative;
  margin: 2.5rem auto 0 0;
  width: 100%;
  max-width: 40rem;
  height: 4rem;

  @media @sm {
    margin-top: 2rem;
  }

  @media @md {
    margin: 0 0 0 4rem;

    .two-col & {
      margin: 0 0 0 0.5rem;
    }
  }
}

.kvn-information__cta {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 4rem;
}

.kvn-information__cta-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 3.2rem;
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 100%;
  margin-right: 1rem;
  background-color: @ds-green;

  svg {
    width: 1.5rem;
    height: 1.5rem;
    margin: auto;
    fill: @ds-white;
  }

  .kvn-information--has-errors & {
    background-color: @ds-yellow;

    svg {
      fill: @ds-black;
    }
  }
}

.kvn-information__cta-text {
  font-size: 1.6rem;
  color: @ds-white;
  font-weight: bold;
  cursor: pointer;
  flex: none;
  margin-right: auto;

  .kvn-information--has-errors & {
    text-decoration: underline;
  }
}

.kvn-information__cta-close {
  display: none;
  cursor: pointer;
  position: relative;
  flex: 0 0 4rem;
  width: 4rem;
  height: 4rem;

  .kvn-information--open & {
    display: block;
  }

  &::before {
    content: "";
    position: absolute;
    top: 1.8rem;
    left: 1rem;
    width: 2rem;
    height: 0.4rem;
    transform: rotate(45deg);
    background: @ds-white;
  }

  &::after {
    content: "";
    position: absolute;
    top: 1.8rem;
    left: 1rem;
    width: 2rem;
    height: 0.4rem;
    transform: rotate(-45deg);
    background: @ds-white;
  }
}

.kvn-information__list {
  display: none;
  position: absolute;
  top: 4.5rem;
  left: 0;
  list-style-type: none;

  .kvn-information--open & {
    display: block;
  }

  .two-col & {
    @media @md {
      right: 0.5rem;
    }
  }
}

.kvn-information__list-item {
  font-size: 1.6rem;
  line-height: 1.36;
  color: @ds-black;
  background: @ds-white;
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 0.1rem 0.4rem 0 fade(@ds-black, 40%);
  margin-bottom: 0.4rem;

  .kvn-information--has-errors &.kvn-information__list-item--fallback {
    display: none;
  }
} 
