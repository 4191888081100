.region-avalon-kundecenter .faq-brand-menu__container {
  display: flex;
  order: 2;
  width: 21rem;
  margin-top: 3rem;
  position: relative;
}
@media screen and (min-width: 768px) {
  .region-avalon-kundecenter .faq-brand-menu__container {
    flex: 0 0 40%;
    order: -1;
    margin-top: 0;
  }
}
@media screen and (min-width: 950px) {
  .region-avalon-kundecenter .faq-brand-menu__container {
    flex: 0 0 21rem;
  }
}
.region-avalon-kundecenter .faq-brand-menu__container.is-open .faq-brand-menu {
  display: block;
}
.region-avalon-kundecenter .faq-brand-menu__container.is-open .faq-brand-menu__button-icon {
  transform: rotate(-180deg);
}
.region-avalon-kundecenter .faq-brand-menu__button {
  background-color: #004b32;
  color: white;
  border: none;
  width: 100%;
  min-height: 5rem;
  padding: 0 1rem;
  position: absolute;
  border-radius: 5px;
}
@media screen and (min-width: 767px) {
  .region-avalon-kundecenter .faq-brand-menu__button {
    position: relative;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
.region-avalon-kundecenter .faq-brand-menu__button-image {
  display: flex;
  max-width: 12rem;
  max-height: 3rem;
  margin: 0 auto;
}
.region-avalon-kundecenter .faq-brand-menu__button-icon {
  fill: white;
  position: absolute;
  right: 1.5rem;
  top: calc(50% - 0.75rem);
  transition: all 200ms;
}
@media screen and (min-width: 767px) {
  .region-avalon-kundecenter .has-suggestions .faq-brand-menu__button {
    border-bottom-left-radius: 0;
  }
}
.region-avalon-kundecenter .faq-brand-menu {
  background-color: white;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.25) 2px 2px 16px 0;
  padding: 1rem;
  display: none;
  width: 100%;
  position: absolute;
  left: 0;
  top: 5rem;
  z-index: 5;
  text-align: center;
}
.region-avalon-kundecenter .faq-brand-menu__title {
  font-size: 1rem;
  text-transform: none;
  color: #2d2d2d;
}
.region-avalon-kundecenter .faq-brand-menu__items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}
.region-avalon-kundecenter .faq-brand-menu__link {
  width: calc(50% - 0.5rem);
  display: block;
}
.region-avalon-kundecenter .faq-brand-menu__image {
  width: 9rem;
  border-radius: 0.5rem;
  padding: 0.5rem;
  height: 3rem;
}
.region-avalon-kundecenter .faq-brand-menu__close-button {
  background-color: #004b32;
  color: white;
  border: 0;
  border-radius: 50%;
}
.region-avalon-kundecenter .two-col__col--left .faq-brand-menu__container {
  margin-top: 3rem;
  order: 2;
  max-width: 21rem;
}
.region-avalon-kundecenter .two-col__col--left .faq-brand-menu__button {
  border-radius: 5px;
}
