.top-navigation-theme-kundecenter .menu-toggle-right {
  background-color: #026B46;
}
.top-navigation-theme-kundecenter .mobile-user-info {
  display: none;
}
.top-navigation-theme-kundecenter .brand-nav {
  background-color: #026B46;
}
@media all and (max-width: 950px) {
  .top-navigation-theme-kundecenter .brand-nav .user-bar .choose-account {
    display: inline-block !important;
  }
  .top-navigation-theme-kundecenter .brand-nav .user-bar .profile-btn {
    display: none !important;
  }
}
.top-navigation-theme-kundecenter .brand-nav .user-bar .login-btn {
  display: none;
}
.top-navigation-theme-kundecenter .corporate-nav {
  background-color: #014B32;
}
.top-navigation-theme-kundecenter .corporate-nav .accountmenu .login-btn,
.top-navigation-theme-kundecenter .corporate-nav .accountmenu .signup-btn,
.top-navigation-theme-kundecenter .corporate-nav .accountmenu .profile-btn,
.top-navigation-theme-kundecenter .corporate-nav .accountmenu .info-bar-item--ballance,
.top-navigation-theme-kundecenter .corporate-nav .accountmenu .accountmenu__group,
.top-navigation-theme-kundecenter .corporate-nav .accountmenu .sub-navigation {
  display: none !important;
}
.top-navigation-theme-kundecenter .corporate-nav .choose-account-btn {
  display: inline-block !important;
}
.top-navigation-theme-kundecenter .mobile-navigation-header {
  background-color: #026B46;
}
.top-navigation-theme-kundecenter .mobile-navigation-item-link:before {
  background-color: #014B32;
}
.top-navigation-theme-kundecenter .mobile-navigation-header {
  background-color: #014B32;
}
.top-navigation-theme-kundecenter .mobile-navigation-footer-icon {
  background-color: #026B46;
}
.top-navigation-theme-kundecenter .mobile-navigation-item-link:before {
  background-color: #026B46;
}
@media all and (min-width: 950px) {
  .top-navigation-theme-kundecenter .corporate-nav__navigation .corporate-nav__list-link,
  .top-navigation-theme-kundecenter .brand-nav__navigation .corporate-nav__list-link {
    color: #fff;
  }
  .top-navigation-theme-kundecenter .corporate-nav__navigation .corporate-nav__list-link:hover,
  .top-navigation-theme-kundecenter .brand-nav__navigation .corporate-nav__list-link:hover {
    color: #fff;
  }
}
@media all and (min-width: 950px) {
  .top-navigation-theme-kundecenter .corporate-nav__navigation .brand-nav__link,
  .top-navigation-theme-kundecenter .brand-nav__navigation .brand-nav__link {
    color: #fff;
  }
}
.top-navigation-theme-kundecenter .corporate-nav__navigation .brand-nav__link:before,
.top-navigation-theme-kundecenter .brand-nav__navigation .brand-nav__link:before {
  background-color: #7f4488;
}
.top-navigation-theme-kundecenter .accountmenu__item {
  color: #fff;
}
.top-navigation-theme-kundecenter .mega-menu__campaign__cta-button {
  background-color: #026B46;
  color: white;
}
.top-navigation-theme-kundecenter .mega-menu__campaign__cta-button:hover {
  background-color: #014B32;
}
@media all and (min-width: 950px) {
  .top-navigation-theme-kundecenter .brand-nav,
  .header-static-fix {
    display: none;
  }
}
