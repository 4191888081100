@import (reference) '../_imports';

.region-avalon-kundecenter {
  .faq-breadcrumb {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 1.6rem;
  }

  .faq-breadcrumb__link {
    color: @ds-green-500;

    &:hover,
    &:active {
      text-decoration: underline;
    }
  }

  .faq-bradcrumb__separator {
    fill: @ds-color-primary--text;
    margin: 0 0.5rem;
  }
}
