@import (reference) '../_imports';

.region-avalon-kundecenter {
  .faq-form {
    padding: 1rem;
    background-color: @background-color;

    @media screen and (min-width: @medium) {
      padding-top: 5rem;
    }
  }

  .faq-form__header {
    padding: 1rem;
    margin-left: -1rem;
    margin-right: -1rem;
    margin-bottom: 3rem;
    border-bottom: 2px solid @border-color;

    @media screen and (min-width: @small) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .faq-form__title {
    text-transform: none;
    font-size: 2.4rem;
    color: @ds-color-primary--text;

    @media screen and (min-width: @small) {
      text-align: center;
    }
  }

  .faq-form__description {
    font-size: 1.6rem;
    font-weight: 400;
    color: @ds-color-primary--text;
    text-transform: none;

    @media screen and (min-width: @small) {
      text-align: center;
    }
  }

  .faq-form__wrap {
    margin: auto;
  }

  .faq-form__row {
    @media screen and (min-width: @small) {
      display: flex;
      justify-content: space-between;

      .faq-form__column {
        width: ~"calc(50% - 0.5rem)";
      }
    }
  }

  .faq-form__group {
    margin-bottom: 5rem;
  }

  .faq-form__group-title {
    text-transform: none;
    font-size: 1.8rem;
    font-weight: 700;
    margin-bottom: 2rem;
  }

  .faq-form__input-group {
    margin-bottom: 1.6rem;
  }

  .faq-form__label {
    display: inline-block;
    font-size: 1.6rem;
    color: @ds-color-primary--text;
    margin-bottom: 0.5rem;
  }

  .is-required {
    .faq-form__label {
      &:after {
        content: '*';
        color: @ds-red-500;
      }
    }
  }

  .faq-form__input-wrap {
    position: relative;
  }

  .faq-form__input-icon {
    margin-right: 1rem;
  }

  .faq-form__state-icon {
    position: absolute;
    height: 1rem;
    width: 1rem;
    top: ~"calc(50% - 0.5rem)";
    right: 1rem;
    opacity: 0;
  }

  .faq-form__input,
  .faq-form__textarea,
  .faq-form__styled-select {
    background-color: @ds-white;
    box-sizing: border-box;
    border: 1px solid @border-color;
    border-radius: 5px;
    display: block;
    padding: 1rem 0;
    text-indent: 1rem;
    line-height: 1.5;
    width: 100%;
    outline: 0;

    &:focus {
      border-color: @ds-color-primary--text;
    }

    .faq-form__styled-select-icon {
      position: absolute;
      top: ~"calc(50% - 0.75rem)";
      right: 1rem;
      z-index: 1;
    }

    .faq-form__select--mobile {
      @media screen and (min-width: @medium) {
        display: none;
      }
    }

    .faq-form__select--desktop {
      display: none;
      padding: 1rem 0;
      text-indent: 1rem;
      cursor: pointer;
      position: relative;
      z-index: 2;

      @media screen and (min-width: @medium) {
        display: block;
      }
    }

    .faq-form__select {
      position: relative;
      z-index: 2; // You need to be able to click on the dropdown icon as well to trigger the select
      background-color: transparent;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      width: 100%;
      border: 0;
      outline: none;
      padding: 1rem 0;
      text-indent: 1rem;
    }
  }

  .faq-form__textarea {
    padding: 1rem;
    text-indent: 0;
  }

  .faq-form__styled-dropdown {
    background-color: @ds-white;
    border: 1px solid @border-color;
    border-top: 0;
    padding: 0 1rem;
    width: 100%;
    position: absolute;
    z-index: 2;

    .avalon-list__item:last-of-type {
      border-bottom: 0;
    }
  }

  .is-open {
    .faq-form__styled-dropdown {
      // Should only be displayed for desktop as phones and tablets should use the native select (that triggers the scroll wheel)
      @media screen and (min-width: @medium) {
        display: block;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
      }
    }

    .faq-form__styled-select {
      @media screen and (min-width: @medium) {
        border-bottom: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    .faq-form__styled-select-icon {
      transform: rotate(180deg);
    }
  }

  .faq-form__brand-container {
    position: relative;

    .faq-brand-menu__button-text {
      display: inline-block;
    }

    .faq-form__selected-brand {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      height: 4.5rem;

      .faq-brand-menu__button-icon {
        fill: @ds-white;
      }
    }

    .faq-form__selected-brand-image {
      max-height: 2.8rem;
      max-width: 10rem;
    }

    .faq-brand-menu {
      display: block;
    }

    .faq-brand-menu__items {
      margin-bottom: 0;
    }

    .faq-brand-menu__image {
      width: 14rem;
      border-radius: 0.5rem;
      padding: 1rem;
      height: 4rem;
    }
  }

  .faq-form__styled-select {
    position: relative;
    padding: 0;
    text-indent: 0;
  }

  .faq-form__input-button {
    cursor: pointer;
    text-align: left;
    position: relative;
  }

  .faq-form__input-button-icon {
    fill: @ds-color-primary--text;
    transition: all 0.5s;
  }

  .faq-form__textarea {
    min-height: 20rem;
    max-width: 100%;
  }

  .recaptcha-container {
    text-align: center;
  }

  .faq-form__recaptcha {
    display: inline-block;
    margin: 0 auto 4rem;
  }

  .faq-form__recaptcha-error {
    color: @ds-red-500;
    margin-bottom: 2rem;
  }

  .faq-form__file-list {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;

    .faq-form__file-item-wrap {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-bottom: 1.6rem;

      @media screen and (min-width: @small) {
        width: ~"calc(50% - 0.5rem)";
      }
    }

    .faq-form__file-item {
      background-color: @ds-white;
      display: flex;
      align-items: center;
      width: 100%;
      box-sizing: border-box;
      border: 1px solid @border-color;
      border-radius: 5px;
      padding: 1rem 3.5rem 1rem 1rem;
      position: relative;
    }

    .faq-form__file-image {
      height: 2rem;
      width: 2rem;
      margin-right: 1rem;
      object-fit: cover;
    }

    .faq-form__file-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .faq-form__file-remove {
      position: absolute;
      top: ~"calc(50% - 1rem)";
      right: 1rem;
      height: 2rem;
      width: 2rem;
      background-color: @border-color;
      color: @ds-white;
      border-radius: 50%;
      border: 0;
      outline: 0;
      padding: 0;
    }

    .has-error {
      color: @ds-red-500;
    }
  }
  
  .has-error {
    .faq-form__input,
    .faq-form__textarea {
      border-color: @ds-red-500;
      color: @ds-red-500;
    }

    .faq-form__state-icon {
      fill: @ds-red-500;
      opacity: 1;
    }

    .faq-form__file-error {
      color: @ds-red-500;
    }
  }

  .is-valid {
    .faq-form__state-icon {
      fill: @ds-green-500;
      opacity: 1;
    }
  }

  .faq-form__error-message-container {
    margin-bottom: 2rem;
  }

  .faq-form__error-message {
    color: @ds-red-500;
    max-width: 100%; // IE11 issue. This has to be set in order to stay within parent container
  }

  .faq-form__error-message--center {
    text-align: center;
  }

  .faq-form__update-option {
    border: 1px solid @ds-green-500;
    padding: 1rem;
    border-radius: 5px;
    display: block;
  }

  .faq-form__update-option--success {
    color: @ds-green-500;
  }

  .faq-form__update-option--error {
    border: 1px solid @ds-red-500;
    color: @ds-red-500;
  }

  .faq-form__button {
    border: 0;
    border-radius: 5px;
    color: @ds-white;
    background-color: @ds-green-500;
    width: 100%;
    min-height: 5rem;
    height: 5rem;
    max-width: 25rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  // Hide the actual file field. This will be triggered by the label click
  .faq-form__file {
    position: absolute;
    left: -999999px;
  }

  .faq-form__receipt-page {
    padding: 5rem 0;
    text-align: center;
  }

  .faq-form__receipt-header {
    margin-bottom: 2rem;
  }

  .faq-form__receipt-icon {
    width: 14rem;
    height: 14rem;
    margin-bottom: 2rem;
  }
}
