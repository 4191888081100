.region-avalon-kundecenter .card-layout-10 .card.card-picture {
  padding: 0;
}
.region-avalon-kundecenter .card-layout-10 .avalon-video {
  background-color: transparent;
  width: 100%;
}
.region-avalon-kundecenter .card-layout-10 .avalon-video .avalon-video__video-inner {
  padding-bottom: 25%;
}
.region-avalon-kundecenter .card-layout-10 .avalon-video .avalon-video__title,
.region-avalon-kundecenter .card-layout-10 .avalon-video .avalon-video__text {
  display: none;
}
.region-avalon-kundecenter .card-layout-10 .avalon-video .u-grid {
  padding: 0;
  max-width: none;
}
