@import (reference) '../_imports';
@import './../Animations.less';

.region-avalon-kundecenter {
  .avalon-contact {
    background: @ds-green-500;
    color: @ds-white;
    text-align: center;
    font-size: 1.6rem;
    font-weight: normal;
  }

  .avalon-contact__item {
    width: ~"calc(100% - 4rem)";
    margin: 0 2rem 2rem;

    @media screen and (min-width: @small) {
      display: inline-block;
      vertical-align: top;
      width: calc(40% - 4rem);
    }

    @media screen and (min-width: @large) {
      width: calc(30% - 4rem);
    }
  }

  .avalon-contact__title {
    font-size: 1.8rem;
    font-weight: bold;
    color: @ds-white;
    text-transform: none;
  }

  .avalon-contact__abstract {
    margin-bottom: 2rem;
  }

  .avalon-contact__item-title {
    font-size: 1.6rem;
    font-weight: bold;
    text-transform: none;
  }

  .avalon-contact__item-link {
    background: var(--contact__item-backgroundColor, @ds-white);
    border-radius: 5px;
    color: var(--contact__item-color, @ds-color-primary--text);
    display: block;
    padding: 1rem 0;
    border: solid var(--contact__item-borderColor, --contact__item-backgroundColor, @ds-white) 3px;
  }

  .avalon-contact__bottom-link {
    border-top: 4px solid @ds-green-100;
    background-color: @ds-green-300;
    color: @ds-white;
    display: block;
    padding: 1rem;
    text-transform: uppercase;

    @media screen and (min-width: @large) {
      display: none;
    }

    &:hover,
    &:active {
      .avalon-contact__bottom-link-icon {
        animation: kundecenter-horizontal-slide 1s infinite linear;
      }    
    }
  }

  .avalon-contact__bottom-link-icon {
    fill: @ds-white;
    margin-left: 1rem;
  }
}
