@keyframes kundecenter-horizontal-slide {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-0.5rem);
  }
  75% {
    transform: translateX(0.5rem);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes kundecenter-vertical-slide {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-0.5rem);
  }
  75% {
    transform: translateY(0.5rem);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes kundecenter-shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
.region-avalon-kundecenter .announcements {
  padding: 3rem 0;
  background-color: #f3f3f3;
}
.region-avalon-kundecenter .announcements__title {
  color: #2d2d2d;
  font-size: 2.2rem;
  font-weight: bold;
  text-transform: none;
  padding: 0 3rem;
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
}
.region-avalon-kundecenter .announcements__list {
  display: flex;
  width: fit-content;
  padding: 0 3rem;
  margin: 0 auto;
}
.region-avalon-kundecenter .announcement__item {
  background-color: white;
  box-sizing: border-box;
  color: #294b4b;
  border-radius: 5px;
  border: 1px solid #c5c5c5;
  padding: 2rem;
  display: inline-block;
  vertical-align: top;
  width: 26rem;
  margin-right: 1rem;
  font-size: 1.6rem;
  position: relative;
  max-height: 20rem;
  overflow-y: hidden;
  height: auto;
}
@media screen and (min-width: 767px) {
  .region-avalon-kundecenter .announcement__item {
    width: 30rem;
    margin-right: 3rem;
  }
}
.region-avalon-kundecenter .announcement__item:nth-last-child(1) {
  margin-right: 0;
}
.region-avalon-kundecenter .announcement__item:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 -0.5rem 1rem 1rem white;
}
.region-avalon-kundecenter .announcement__item:hover .announcement__icon,
.region-avalon-kundecenter .announcement__item:active .announcement__icon {
  animation: kundecenter-horizontal-slide 1s infinite linear;
}
.region-avalon-kundecenter .announcement__brand-logo {
  display: inline-block;
  background-color: #f3f3f3;
  height: 14px;
  width: 80px;
}
.region-avalon-kundecenter .announcement__title {
  font-weight: bold;
  text-transform: none;
  display: block;
}
.region-avalon-kundecenter .announcement__abstract {
  display: block;
  overflow: hidden;
}
.region-avalon-kundecenter .announcement__icon {
  position: absolute;
  z-index: 1;
  bottom: 1rem;
  right: 1rem;
}
@media screen and (min-width: 767px) {
  .region-avalon-kundecenter .announcement__icon {
    bottom: 2rem;
    right: 2rem;
  }
}
.region-avalon-kundecenter .has-swiper .swiper-wrapper {
  margin-bottom: 5rem !important;
}
.region-avalon-kundecenter .has-swiper .swiper-scrollbar {
  overflow: hidden;
  width: 50%;
  height: 1rem;
  max-width: 50%;
  transform: translateX(50%);
  bottom: 0;
  margin: 0 0 2rem;
}
.region-avalon-kundecenter .has-swiper .swiper-scrollbar-drag {
  position: relative;
  height: 3rem;
  width: 3rem;
  background-color: #2d2d2d;
  margin-top: -1rem;
  z-index: 100;
}
