@import (reference) '../_imports';

.region-avalon-kundecenter {
  .faq-backlink__link {
    display: inline-flex;
    align-items: center;
    margin: 2rem 0;
    color: @ds-green-500;

    &:hover,
    &:active {
      text-decoration: underline;
    }
  }

  .faq-backlink__icon {
    fill: @ds-green-500;
    margin-right: 0.5rem;
  }
}
