@import (reference) '../_imports';

.region-avalon-kundecenter {
  .faq-article__title {
    color: @ds-color-primary--text;
    font-size: 3.7rem;
    font-weight: bold;
    text-transform: none;
    margin-bottom: 1rem;
  }

  .faq-article__brand-logo {
    border-radius: 5px;
    padding: 0.5rem 1rem;
    max-height: 2.5rem;
  }

  .faq-article__body {
    color: @ds-color-primary--text;
    font-size: 1.6rem;
    margin-bottom: 1rem;

    img,
    table {
      max-width: 100%;
    }
  }
}
