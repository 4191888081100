@import (reference) './_imports';

.region-avalon-kundecenter {
  .two-col {
    background-color: @ds-white;
    display: flex;
    justify-content: space-between;
    margin-top: 4rem;
  }

  .two-col__col--left {
    margin-right: 3rem;
    width: 32rem;
    display: flex;
    position: relative;

    @media screen and (max-width: @medium) {
      display: none;
    }
  }

  .two-col__col--right {
    flex: 1;
    padding-top: 3rem;
    padding-bottom: 3rem;

    @media screen and (max-width: @medium) {
      padding: 3rem 1rem;
    }

    @media screen and (min-width: @medium) {
      margin-left: -15rem;
    }

    @media screen and (min-width: @large) {
      margin-left: auto;
    }
  }
}
