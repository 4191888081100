@keyframes kundecenter-horizontal-slide {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-0.5rem);
  }
  75% {
    transform: translateX(0.5rem);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes kundecenter-vertical-slide {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-0.5rem);
  }
  75% {
    transform: translateY(0.5rem);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes kundecenter-shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}