.avalon-sidebar {
  background-color: #00281b;
  position: relative;
  display: flex;
  flex: 1;
  padding-top: 3rem;
  padding-bottom: 3rem;
  min-height: 100vh;
}
@media screen and (max-width: 1023px) {
  .avalon-sidebar {
    margin-right: 3rem;
    position: absolute;
    height: 100%;
    width: 100%;
    transform: translateX(-20rem);
    transition: transform 300ms ease-out;
    transform-style: preserve-3d;
  }
}
.avalon-sidebar.is-open {
  transform: translateX(0);
  overflow: visible;
  transform-style: flat;
}
.avalon-sidebar.is-open .avalon-sidebar__dimmed {
  opacity: 1;
  pointer-events: all;
}
.avalon-sidebar.is-open .avalon-sidebar__container {
  overflow: hidden;
}
.avalon-sidebar.is-open .avalon-sidebar__handle {
  right: -7rem;
  transform: translateZ(-1px) rotate(-180deg);
}
.avalon-sidebar .faq-search {
  background-color: unset;
}
.avalon-sidebar__container {
  flex: 1;
  position: relative;
  transform-style: preserve-3d;
}
.avalon-sidebar__dimmed {
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 32rem;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  transition: opacity 200ms ease-out;
}
.avalon-sidebar__handle {
  border: 0;
  cursor: pointer;
  outline: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 10rem;
  height: 50rem;
  max-height: calc(100% - 15rem);
  position: absolute;
  right: -3rem;
  top: 15rem;
  transform: translateZ(-1px);
  border-radius: 50%;
  background-color: #FEB700;
}
@media screen and (min-width: 1024px) {
  .avalon-sidebar__handle {
    display: none;
  }
}
